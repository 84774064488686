import React, { Component, Suspense } from 'react';

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { memberRequested, providerRequested,invoiceRequested,handleQuery }
  from '../../redux/actions/keyInfoActions'
  import { logoutFromView }
  from '../../redux/actions/authActions'
import GetInTouch from './GetInTouch/GetInTouch';


const NavbarPage = React.lazy(() => import('../../components/Navbar/Navbar_Page'));
const Section = React.lazy(() => import('./section'));
const About = React.lazy(() => import('../../components/About/about'));
const Process = React.lazy(() => import('../../components/Process/process'));
const Portfolio = React.lazy(() => import('../../components/Portfolio/portfolio'));
const Counter = React.lazy(() => import('../../components/Counter/counter'));
const OurTeam = React.lazy(() => import('../../components/Team/our-team'));
const Footer = React.lazy(() => import('../../components/Footer/footer'));
const Testimonials = React.lazy(() => import('../../components/Testimonials/testimonials'));
const Pricing = React.lazy(() => import('../../components/Pricing/pricing'));
const Blog = React.lazy(() => import('../../components/Blog/blog'));
const Cta = React.lazy(() => import('../../components/Cta/Cta'));
const Mandate = React.lazy(() => import('./Mandate'));




class MyIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navItems : [
                { id: 1 , idnm : "home", navheading: "Home" },
                // { id: 2 , idnm : "about", navheading: "About" }
                // { id: 3 , idnm : "portfolio", navheading: "Portfolio" },
                // { id: 4 , idnm : "team", navheading: "Team" },
                // { id: 5 , idnm : "testimonial", navheading: "Testimonial" },
                // { id: 6 , idnm : "pricing", navheading: "Pricing" },
                // { id: 7 , idnm : "blog", navheading: "Blog" },
                // { id: 8 , idnm : "contact", navheading: "Contact" },
            ],
            pos : document.documentElement.scrollTop,
            imglight : true,
            navClass : "",
            openRegForm:false,
            openLoginForm:false,
            signstate: false,
            registerState: true,
            searchResult:[],
            employeees:[
              {
                name:'Lanre Ladipo',
                email:'lanreladipo@gmail.com',
                phone:'08096654413',
                picture: require('../../assets/images/lanre.jpg'),
                org:[1,4,7]
              },
              {
                name:'Dupe Alabi',
                email:'dupealabi@gmail.com',
                phone:'08096622423',
                picture: require('../../assets/images/04.jpg'),
                org:[1,3,5]
              },
              {
                name:'Sarah Lawal',
                email:'sarahlawal@gmail.com',
                phone:'08096622443',
                picture: require('../../assets/images/07.jpg'),
                org:[0,3,2]
              }
            ]
        };
    }

    componentDidMount() {
        // window.addEventListener("scroll", this.scrollNavigation, true);
    }

    componentWillUnmount(){
        // window.removeEventListener("scroll", this.scrollNavigation, true);
    }

    scrollNavigation = () => {
        var scrollup=document.documentElement.scrollTop;
        if(scrollup > this.state.pos)
        {
            this.setState({navClass : "nav-sticky", imglight : false});
        }
        else
        {
            this.setState({navClass : "", imglight : true});
        }
    };

    Loader = () => {
        return (
            <div id="preloader">
                <div id="status">
                    <div className="spinner">Loading...</div>
                </div>
            </div>
        );
    }

    updateSearch(e){
       const { employeees } = this.state

       const searchResult = employeees.filter(res => {
         return JSON.stringify(res).toLowerCase().replace(/\s/g, '').includes(e.target.value.toLowerCase())
       })
        this.setState({
          searchText:e.target.value,
          searchResult: e.target.value && e.target.value.length > 4
          ? searchResult
          : []
        },()=>{
          // console.log('xxxx',this.state)
          // this.props.passStateVal(this.state)
        })
    }

    passStateVal(items){
      // console.log('items',items)
      const data = this.state
      // if(items.signstate){
        this.setState({
          ...data,
          ...items
        },()=>{
          // console.log('items',this.state)
        })
      // }

    }

    render() {
      const { signstate } = this.state
        return (
            <React.Fragment>
                <Suspense fallback = {this.Loader()} >

    							<div class="">




    								<div class="subnavbar">
    									<div class="subnavbar-inner">
    										<div class="toolbar tabbar tabbar-scrollable toolbar-category">
    											<div class="toolbar-inner">
    												<a href="#tab1" class="tab-link tab-link-active">Home</a>
    												<a href="/Employer" class="tab-link">Employer</a>
    											</div>
    										</div>
    									</div>
                      <div
                        style={{
                        top:1,
                        display:'flex',
                        float:'right',
                        flexDirection:'row',
                          marginRight:10
                      }}>
                         <a
                           cursor='pointer'
                           onClick={()=>{
                              this.setState({
                                openLoginForm:true,
                                openRegForm:false
                              })
                           }}
                         style={{
                           paddingRight:10
                         }}> {signstate ? 'VareTech' : 'Login'}
                         </a>
      										<img
                          style={{
                            width:30,
                            height:30,
                            borderRadius:25,
                          }}
                          src={require('../../assets/images/09.jpg')} alt="" />
      								</div>
    								</div>
    							</div>

                    {/* Importing Navbar */}

                    {/* Importing section */}

                    {/* Importing get in touch */}
                    <GetInTouch
                       {...this.state}
                       passStateVal={this.passStateVal.bind(this)}
                       updateSearch={this.updateSearch.bind(this)}
                    />

                    {/* Importing footer */}
                    <Footer/>
                </Suspense>

            </React.Fragment>
        );
    }
}

// export default Index1;

const mapStateToProps = (state, ownProps) => {
  const storeData = state
  // console.log('xxxx',state)
  return {
    info: storeData.keyInfo
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onHandleQuery: (formData) => {
      dispatch(handleQuery(formData))
    },
    onLogoutFromView: () => {
      dispatch(logoutFromView())
    }
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyIndex)
