import { combineReducers } from 'redux'
//import entityById from './entityById'
import {
  GENERAL_REQUEST,
  GENERAL_SUCCESS,
  GENERAL_FAILURE,

  DRIVER_REQUEST,
  DRIVER_SUCCESS,
  DRIVER_FAILURE,

  UNITS_REQUEST,
  UNITS_SUCCESS,
  UNITS_FAILURE,

  TRIPREPORT_REQUEST,
  TRIPREPORT_SUCCESS,
  TRIPREPORT_FAILURE,

  LOAD_REQUEST,
  LOAD_SUCCESS,
  LOAD_FAILURE,

  DRUGTEST_REQUEST,
  DRUGTEST_SUCCESS,
  DRUGTEST_FAILURE,

  IMPORTANTFILINGS_REQUEST,
  IMPORTANTFILINGS_SUCCESS,
  IMPORTANTFILINGS_FAILURE,

  ISSUES_REQUEST,
  ISSUES_SUCCESS,
  ISSUES_FAILURE,

  MAINTENANCE_REQUEST,
  MAINTENANCE_SUCCESS,
  MAINTENANCE_FAILURE,

  MAINTENANCERECORD_REQUEST,
  MAINTENANCERECORD_SUCCESS,
  MAINTENANCERECORD_FAILURE,

  MILEAGERECORD_REQUEST,
  MILEAGERECORD_SUCCESS,
  MILEAGERECORD_FAILURE,

  PAYROLL_REQUEST,
  PAYROLL_SUCCESS,
  PAYROLL_FAILURE,

  CALENDAR_REQUEST,
  CALENDAR_SUCCESS,
  CALENDAR_FAILURE


} from '../actions'
import InitialData from '../../data';





const drugTest = (state = InitialData['drugTest'], action = {}) => {
  switch (action.type) {
    case DRUGTEST_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case DRUGTEST_SUCCESS: {
      return {
        ...state,
        tableModel: 'drugTest',
        table: 'truck_drugTest',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case DRUGTEST_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const importantFilings = (state = InitialData['importantFilings'], action = {}) => {
  switch (action.type) {
    case IMPORTANTFILINGS_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case IMPORTANTFILINGS_SUCCESS: {
      return {
        ...state,
        tableModel: 'importantFilings',
        table: 'truck_importantFilings',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case IMPORTANTFILINGS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const issues = (state = InitialData['issues'], action = {}) => {
  switch (action.type) {
    case ISSUES_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case ISSUES_SUCCESS: {
      return {
        ...state,
        tableModel: 'issues',
        table: 'truck_issues',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case ISSUES_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const load = (state = InitialData['load'], action = {}) => {
  switch (action.type) {
    case LOAD_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case LOAD_SUCCESS: {
      return {
        ...state,
        tableModel: 'load',
        table: 'truck_load',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case LOAD_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const maintenance = (state = InitialData['maintenance'], action = {}) => {
  switch (action.type) {
    case MAINTENANCE_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case MAINTENANCE_SUCCESS: {
      return {
        ...state,
        tableModel: 'maintenance',
        table: 'truck_maintenance',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case MAINTENANCE_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const maintenanceRecord = (state = InitialData['maintenanceRecord'], action = {}) => {
  switch (action.type) {
    case MAINTENANCERECORD_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case MAINTENANCERECORD_SUCCESS: {
      return {
        ...state,
        tableModel: 'maintenanceRecord',
        table: 'truck_maintenanceRecord',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case MAINTENANCERECORD_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const mileageRecord = (state = InitialData['mileageRecord'], action = {}) => {
  switch (action.type) {
    case MILEAGERECORD_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case MILEAGERECORD_SUCCESS: {
      return {
        ...state,
        tableModel: 'mileageRecord',
        table: 'truck_mileageRecord',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case MILEAGERECORD_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const payroll = (state = InitialData['payroll'], action = {}) => {
  switch (action.type) {
    case PAYROLL_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case PAYROLL_SUCCESS: {
      return {
        ...state,
        tableModel: 'payroll',
        table: 'truck_payroll',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case PAYROLL_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const test = (state = InitialData['test'], action = {}) => {
  switch (action.type) {
    case TRIPREPORT_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case TRIPREPORT_SUCCESS: {
      return {
        ...state,
        tableModel: 'tripReport',
        table: 'truck_tripReport',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case TRIPREPORT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}


const tripReport = (state = InitialData['tripReport'], action = {}) => {

  switch (action.type) {
    case TRIPREPORT_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case TRIPREPORT_SUCCESS: {
      return {
        ...state,
        tableModel: 'tripReport',
        table: 'truck_tripReport',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case TRIPREPORT_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const units = (state = InitialData['units'], action = {}) => {

  switch (action.type) {
    case UNITS_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case UNITS_SUCCESS: {
      return {
        ...state,
        tableModel: 'Units',
        table: 'truck_units',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case UNITS_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const driver = (state = InitialData['driver'], action = {}) => {

  switch (action.type) {
    case DRIVER_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case DRIVER_SUCCESS: {
      return {
        ...state,
        tableModel: 'driver',
        table: 'truck_driver',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case DRIVER_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}

const calendar = (state = InitialData['calendar'], action = {}) => {

  switch (action.type) {
    case CALENDAR_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case CALENDAR_SUCCESS: {
      return {
        ...state,
        tableModel: 'calendar',
        table: 'truck_calendar',
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case CALENDAR_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}


const generalData = (state = [], action = {}) => {

  switch (action.type) {
    case GENERAL_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: null
      }
    }
    case GENERAL_SUCCESS: {
      return {
        ...state,
        tableData:action.payload.response
          ? action.payload.response : [],
        response:action.payload.response
          ? action.payload.response : [],
        resource:action.payload.resource
          ? action.payload.resource : '',
        isFetching: false,
        error: null
      }
    }
    case GENERAL_FAILURE: {
      return {
        ...state,
        isFetching: false,
        error: action.error
      }
    }
    default:
      return state
  }
}


const combined = combineReducers({
  driver,
  drugTest,
  importantFilings,
  issues,
  load,
  maintenance,
  maintenanceRecord,
  mileageRecord,
  payroll,
  tripReport,
  units,
  calendar
})

export default combined
