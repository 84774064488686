import React, { Component, Fragment } from 'react';
import { Container, Row, Col, Media, FormGroup } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Import Section Title
import SectionTitle from "../../../components/common/section-title";

//Import Images
import map from "../../../assets/images/features/map.png";
import orgs from "../../../data/orgs";


class GetInTouch extends Component {
  constructor(props) {
      super(props);
      this.state = {
          openRegForm:false,
          openLoginForm:false,
          signstate: false,
          registerState: true,
          searchResult:[],
          employeees:[
            {
              name:'Lanre Ladipo',
              email:'lanreladipo@gmail.com',
              phone:'08096654413',
              picture: require('../../../assets/images/lanre.jpg'),
              org:[1,4,7]
            },
            {
              name:'Dupe Alabi',
              email:'dupealabi@gmail.com',
              phone:'08096622423',
              picture: require('../../../assets/images/04.jpg'),
              org:[1,3,5]
            },
            {
              name:'Sarah Lawal',
              email:'sarahlawal@gmail.com',
              phone:'08096622443',
              picture: require('../../../assets/images/07.jpg'),
              org:[0,3,2]
            }
          ]
        }
      }

      // updateSearch(e){
      //    const { employeees } = this.props
      //
      //    const searchResult = employeees.filter(res => {
      //      return JSON.stringify(res).toLowerCase().replace(/\s/g, '').includes(e.target.value.toLowerCase())
      //    })
      //     this.setState({
      //       searchText:e.target.value,
      //       searchResult: e.target.value && e.target.value.length > 4
      //       ? searchResult
      //       : []
      //     },()=>{
      //       this.props.passStateVal(this.state)
      //     })
      // }



    render() {

      const {
        signstate,
        registerState,
        searchResult,
        searchText,
        openRegForm,
        openLoginForm } = this.props

      // console.log('searchResult',this.props)

      const FormDetails = () => {
        return   <Fragment>
        <SectionTitle
              title="Pro-Verify"
              // subtitle="Contact"
              // desc="Porttitor dolor donec pulvinar tortor nisi quis dapibus tortor commodo sed Pellentesque hendrerit pellentesque libero nec sollicitudin."
            />
            <Row className="mt-5 pt-2">
              <Col lg={5}>
                  <div className="contact-info">
                      <div style={{backgroundImage: `url(${map})`, backgroundRepeat : "no-repeat"}} >

                          <Media>
                              <i className="mdi mdi-map-marker text-primary h4"></i>
                              <Media body className=" ml-4">
                                  <div className="text-muted">2276 Lynn Ogden Lane Beaumont
                                      <br/>Lodgeville Road TX 77701</div>
                              </Media>
                          </Media>

                          <Media className="mt-4">
                              <i className="mdi mdi-phone text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Phone: +71 612-234-4023
                                      <br/>Fax: +954-627-9727</div>
                              </Media>
                          </Media>

                          <Media className="media mt-4">
                              <i className="mdi mdi-calendar-clock text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Monday-friday: 9.00-19.00
                                      <br/>Saturday-Sunday: Holiday</div>
                              </Media>
                          </Media>

                          <Media className="media mt-4">
                              <i className="mdi mdi-email text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Email: FredVWeaver@rhyta.com</div>
                              </Media>
                          </Media>

                      </div>

                  </div>
              </Col>

              <Col lg={7}>
                  <div className="custom-form">
                      <div id="message"></div>
                      <AvForm name="contact-form" id="contact-form">
                          <Row>
                              <Col sm={12} lg={12}>
                                  <FormGroup className="mt-3">
                                  <AvField
                                      name="name"
                                      id="name"
                                      type="text"
                                      className="form-control"
                                      placeholder="Your name"
                                      errorMessage="Enter Your Name"
                                      validate={{ required: { value: true } }}
                                  />
                                  </FormGroup>
                              </Col>
                          </Row>

                          <Row>
                              <Col lg={6}>
                                  <FormGroup className="mt-3">
                                  <AvField
                                      name="email"
                                      id="email"
                                      type="email"
                                      className="form-control"
                                      placeholder="Your email"
                                      errorMessage="Enter Your email"
                                      validate={{
                                          required: { value: true },
                                          email: { value: true }
                                      }}
                                  />
                                  </FormGroup>
                              </Col>

                              <Col lg={6}>
                                  <FormGroup className="mt-3">
                                    <AvField
                                      name="number"
                                      id="number"
                                      type="number"
                                      className="form-control"
                                      placeholder="Phone number"
                                      errorMessage="Enter Your Phone number"
                                      validate={{
                                          required: { value: true },
                                      }}
                                   />
                                  </FormGroup>
                              </Col>
                          </Row>

                          <Row>
                              <Col sm={12} lg={12}>
                                  <FormGroup className="mt-3">
                                      <textarea name="comments" id="comments" rows="5" className="form-control" placeholder="Your message"></textarea>
                                  </FormGroup>
                              </Col>
                          </Row>

                          <Row>
                              <Col sm={12} lg={12}>
                                  <div className="mt-3">
                                      <input type="submit" id="submit" name="send" className="submitBnt btn btn-primary" value="Send Message"/>
                                      <div id="simple-msg"></div>
                                  </div>
                              </Col>
                          </Row>
                      </AvForm>
                  </div>
              </Col>
          </Row>
        </Fragment>
      }

      const SigninDetails = () => {
        return   <Fragment>
        <Row style={{
          marginTop:40
        }}>
            <Col lg="12">
                <div className="header-title text-center">
                <div className="text-uppercase text-muted mb-2">{'Results found!  Please signin to veiw details.  '}</div>

                {!openRegForm && !openLoginForm
                  ? <div style={{
                      display:'flex',
                      flexWrap:'wrap',
                      justifyContent: 'center',

                    }}>
                      <Row style={{
                          width:'40%'
                      }}>
                        <Col sm={12} lg={12}>
                            <div className="mt-3">
                                <input
                                  onClick={()=>{
                                     this.props.passStateVal({
                                       openLoginForm:true,
                                       openRegForm:false
                                     })
                                  }}
                                type="submit"
                                id="submit"
                                name="send"
                                className="submitBnt btn btn-primary"
                                value="Login"/>
                                <div style={{
                                  paddingTop:20
                                }} id="simple-msg">New to Proverify?  Click here to
                                <span
                                onClick={()=>{
                                  this.props.passStateVal({
                                    openRegForm:true,
                                    openLoginForm:false
                                  })
                                }}
                                style={{paddingLeft:3,color:'blue'}}>
                                Subscribe
                                </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div> : null}

                </div>
            </Col>
        </Row>

          {openRegForm
            ? <div>
           <SectionTitle
              // title=""
              subtitle="Please tell us about you and one of our agents will contact you back with instructions for your login."
              // desc="Porttitor dolor donec pulvinar tortor nisi quis dapibus tortor commodo sed Pellentesque hendrerit pellentesque libero nec sollicitudin."
            />
            <Row style={{
              display:'flex',
              flexWrap:'wrap',
              justifyContent: 'center',
            }} className="mt-1 pt-2">

                  <div className="custom-form">
                      <div id="message"></div>
                      <AvForm name="contact-form" id="contact-form">
                          <Row>
                              <Col sm={12} lg={12}>
                                  <FormGroup className="mt-3">
                                  <AvField
                                      name="name"
                                      id="name"
                                      type="text"
                                      className="form-control"
                                      placeholder="Your name"
                                      errorMessage="Enter Your Name"
                                      validate={{ required: { value: true } }}
                                  />
                                  </FormGroup>
                              </Col>
                          </Row>

                          <Row>
                              <Col lg={6}>
                                  <FormGroup className="mt-3">
                                  <AvField
                                      name="email"
                                      id="email"
                                      type="email"
                                      className="form-control"
                                      placeholder="Your email"
                                      errorMessage="Enter Your email"
                                      validate={{
                                          required: { value: true },
                                          email: { value: true }
                                      }}
                                  />
                                  </FormGroup>
                              </Col>

                              <Col lg={6}>
                                  <FormGroup className="mt-3">
                                    <AvField
                                      name="number"
                                      id="number"
                                      type="number"
                                      className="form-control"
                                      placeholder="Phone number"
                                      errorMessage="Enter Your Phone number"
                                      validate={{
                                          required: { value: true },
                                      }}
                                   />
                                  </FormGroup>
                              </Col>
                          </Row>

                          <Row>
                              <Col sm={12} lg={12}>
                                  <FormGroup className="mt-3">
                                      <textarea name="comments" id="comments" rows="5" className="form-control" placeholder="Your message"></textarea>
                                  </FormGroup>
                              </Col>
                          </Row>

                          <Row>
                              <Col sm={12} lg={12}>
                                  <div className="mt-3">
                                      <input
                                         onClick={()=>{
                                          this.props.passStateVal({
                                            signstate:true,
                                            openRegForm:false,
                                            openLoginForm:false
                                          })
                                        }}
                                        type="submit" id="submit" name="send" className="submitBnt btn btn-primary" value="Send Message"/>
                                      <div id="simple-msg"></div>
                                  </div>
                              </Col>
                          </Row>
                      </AvForm>
                  </div>
            </Row>
            </div> : null}

            {openLoginForm
               ? <div className="custom-form">
                    <div id="message"></div>
                    <AvForm name="contact-form" id="contact-form">
                        <Row>
                            <Col sm={12} lg={12}>
                                <FormGroup className="mt-3">
                                <AvField
                                    name="email"
                                    id="email"
                                    type="text"
                                    className="form-control"
                                    placeholder="Your Email"
                                    errorMessage="Enter Email"
                                    validate={{ required: { value: true } }}
                                />
                                <AvField
                                    name="pw"
                                    id="pw"
                                    type="text"
                                    className="form-control"
                                    placeholder="Your PW"
                                    errorMessage="Enter Your Password"
                                    validate={{ required: { value: true } }}
                                />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4} lg={4}>
                                <div className="mt-3">
                                    <input
                                    onClick={()=>{
                                      this.props.passStateVal({
                                        signstate:true,
                                        openRegForm:false,
                                        openLoginForm:false
                                      })
                                    }}
                                    type="submit"
                                    id="submit"
                                    name="send"
                                    className="submitBnt btn btn-primary"
                                    value="Submit"/>
                                    <div id="simple-msg"></div>
                                </div>
                            </Col>
                            <Col sm={4} lg={4}>
                                <div className="mt-3">
                                    <input type="submit"
                                    onClick={()=>{
                                      this.props.passStateVal({
                                        openRegForm:false,
                                        openLoginForm:false
                                      })
                                    }}
                                    id="submit"
                                    name="send"
                                    className="submitBnt btn btn-secondary"
                                    value="Cancel"/>
                                    <div id="simple-msg"></div>
                                </div>
                            </Col>
                        </Row>
                      </AvForm>
                   </div>
                : null}
          </Fragment>
      }


      const RegisterDetails = () => {
        return   <Fragment>
            <SectionTitle
              title="Register"
              // subtitle="Contact"
              // desc="Porttitor dolor donec pulvinar tortor nisi quis dapibus tortor commodo sed Pellentesque hendrerit pellentesque libero nec sollicitudin."
            />
            <Row className="mt-5 pt-2">
              <Col lg={5}>
                  <div className="contact-info">
                      <div style={{backgroundImage: `url(${map})`, backgroundRepeat : "no-repeat"}} >

                          <Media>
                              <i className="mdi mdi-map-marker text-primary h4"></i>
                              <Media body className=" ml-4">
                                  <div className="text-muted">2276 Lynn Ogden Lane Beaumont
                                      <br/>Lodgeville Road TX 77701</div>
                              </Media>
                          </Media>

                          <Media className="mt-4">
                              <i className="mdi mdi-phone text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Phone: +71 612-234-4023
                                      <br/>Fax: +954-627-9727</div>
                              </Media>
                          </Media>

                          <Media className="media mt-4">
                              <i className="mdi mdi-calendar-clock text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Monday-friday: 9.00-19.00
                                      <br/>Saturday-Sunday: Holiday</div>
                              </Media>
                          </Media>

                          <Media className="media mt-4">
                              <i className="mdi mdi-email text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Email: FredVWeaver@rhyta.com</div>
                              </Media>
                          </Media>

                      </div>

                  </div>
              </Col>

              <Col lg={7}>
                  <div className="custom-form">
                      <div id="message"></div>
                      <AvForm name="contact-form" id="contact-form">
                          <Row>
                              <Col sm={12} lg={12}>
                                  <FormGroup className="mt-3">
                                  <AvField
                                      name="name"
                                      id="name"
                                      type="text"
                                      className="form-control"
                                      placeholder="Your name"
                                      errorMessage="Enter Your Name"
                                      validate={{ required: { value: true } }}
                                  />
                                  </FormGroup>
                              </Col>
                          </Row>

                          <Row>
                              <Col lg={6}>
                                  <FormGroup className="mt-3">
                                  <AvField
                                      name="email"
                                      id="email"
                                      type="email"
                                      className="form-control"
                                      placeholder="Your email"
                                      errorMessage="Enter Your email"
                                      validate={{
                                          required: { value: true },
                                          email: { value: true }
                                      }}
                                  />
                                  </FormGroup>
                              </Col>

                              <Col lg={6}>
                                  <FormGroup className="mt-3">
                                    <AvField
                                      name="number"
                                      id="number"
                                      type="number"
                                      className="form-control"
                                      placeholder="Phone number"
                                      errorMessage="Enter Your Phone number"
                                      validate={{
                                          required: { value: true },
                                      }}
                                   />
                                  </FormGroup>
                              </Col>
                          </Row>

                          <Row>
                              <Col sm={12} lg={12}>
                                  <FormGroup className="mt-3">
                                      <textarea name="comments" id="comments" rows="5" className="form-control" placeholder="Your message"></textarea>
                                  </FormGroup>
                              </Col>
                          </Row>

                          <Row>
                              <Col sm={12} lg={12}>
                                  <div className="mt-3">
                                      <input type="submit" id="submit" name="send" className="submitBnt btn btn-primary" value="Send Message"/>
                                      <div id="simple-msg"></div>
                                  </div>
                              </Col>
                          </Row>
                      </AvForm>
                  </div>
              </Col>
            </Row>
          </Fragment>
      }

      const DetailedResult = ({item}) => {
        // console.log('item',item)
        return   <Fragment >
            <SectionTitle
              title="Search Result"
            />
            <Row style={{
              padding:30,
              display:'flex',
              flexWrap:'wrap',
              justifyContent: 'center',
            }} className="mt-1 pt-2">

                       <div className="-">
                           <div style={{backgroundImage: `url(${map})`, backgroundRepeat : "no-repeat"}} >
                             <Media>
                              <img
                                style={{
                                  width:80
                                }}

                                src={item.picture}
                                alt="Girl in a jacket"
                              />
                            <Media body className=" ml-4">
                              <div className="text-muted"><div style={{
                                borderRadius:25,
                                // backgroundColor:'rgba(0,0,0,.54)',
                                // position:'absolute',
                                top:2,
                                margin:5,
                                padding:5,
                                color:'black'
                              }}>
                              <i className="mdi mdi-checkbox-marked-outline text-primary h4" />
                              Picture Verified
                              </div></div>
                           </Media>
                         </Media>

                          <Media>
                              <i className="mdi mdi-account text-primary h4"></i>
                              <Media body className=" ml-4">
                                  <div className="text-muted">Name:  {item.name}</div>
                              </Media>
                          </Media>

                          <Media className="media mt-4">
                              <i className="mdi  mdi-account-circle text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Contact Verification:
                                      <div><i className="mdi mdi-checkbox-marked-outline text-primary h4" />Email: {item.email ? item.email : 'NA'}</div>
                                      <div><i className="mdi mdi-checkbox-marked-outline text-primary h4" />Phone: {item.phone ? item.phone : 'NA'}</div>
                                      <div><i className="mdi mdi-checkbox-blank-outline text-primary h4" />Address: {item.address ? item.address : 'NA'}</div>

                                  </div>
                              </Media>
                          </Media>

                          <Media className="media mt-4">
                              <i className="mdi mdi-account-check text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Professional Orgs Verification:
                                    {
                                      item.org
                                      && item.org.length > 0
                                      ? item.org.map((res, index) => {
                                        return orgs[res] && <div key={'ssds'+index}><i className="mdi mdi-checkbox-marked-outline text-primary h4"></i>{orgs[res].name}</div>
                                      })
                                      : null
                                    }
                                  </div>
                              </Media>
                          </Media>

                          <Media className="media mt-4">
                              <i className="mdi mdi-school text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Education Verification:
                                  <br/><i className="mdi mdi-checkbox-blank-outline text-primary h4"></i>NA
                                  </div>
                              </Media>
                          </Media>

                          <Media className="media mt-4">
                              <i className="mdi mdi-school text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Endorsement Verification:
                                  <br/><i className="mdi mdi-checkbox-marked-outline text-primary h4"></i>Villa Consulting
                                  <br/><i className="mdi mdi-checkbox-blank-outline text-primary h4"></i>NA
                                  </div>
                              </Media>
                          </Media>

                          <Media className="media mt-4">
                              <i className="mdi mdi-trophy-award text-primary h4"></i>
                              <Media body className="ml-4">
                                  <div className="text-muted">Certifications:
                                  <br/><i className="mdi mdi-checkbox-blank-outline text-primary h4"></i>NA
                                  </div>
                              </Media>
                          </Media>
                      </div>
                  </div>
            </Row>
          </Fragment>
      }


        return (
            <React.Fragment>
                <section className="section" id="contact">
                    <Container>
                      <SectionTitle
                        title="Pro-Verify"
                        // subtitle="Contact"
                        // desc="Porttitor dolor donec pulvinar tortor nisi quis dapibus tortor commodo sed Pellentesque hendrerit pellentesque libero nec sollicitudin."
                      />
                      <AvForm name="contact-form" id="contact-form">
                          <Row>
                              <Col sm={12} lg={12}>
                                  <FormGroup className="mt-3">
                                  <AvField
                                      onChange={(e)=>{
                                        this.props.updateSearch(e)
                                      }}
                                      style={{
                                        backgroundColor:'#f2f3f5',
                                        borderRadius:25
                                      }}
                                      name="name"
                                      id="name"
                                      type="text"
                                      className="form-control"
                                      placeholder="    Search by name, phone or email"
                                      errorMessage="Enter Your Name"
                                      validate={{ required: { value: true } }}
                                  />
                                  </FormGroup>
                              </Col>
                          </Row>
                       </AvForm>


                       {/*<FormDetails />*/}

                       {signstate
                         && searchResult
                         && searchResult.length > 0
                         ? searchResult.map((res, index) => {
                           return <div
                           key={'sae3sds'+index}
                           style={{
                             paddingTop:40
                           }}>
                           <DetailedResult item={res} />
                           </div>
                         })
                         : (!signstate && searchResult.length > 0)
                         || openLoginForm
                         ? <SigninDetails />
                         : searchText && searchText.length>0
                         ? 'No results found'
                         : null
                         }

                       {!registerState ? <RegisterDetails />: null}


                    </Container>
                </section>
            </React.Fragment>
        );
    }
}

export default GetInTouch;
