 // import axios from 'axios';
 import RESTCall from './restApi'
 import axios from 'axios';
 // import Constants from 'expo-constants';
 // import environment from "../components/environment";
 // const env = environment('staging');
 // const string = env.restApi;


 //delete document
 // http://localhost:5000/proverify/delete/
 //post
 // {
//     "key":["item_id","user_id"],
//     "doc":"vare_vote",
//     "data": [
//         {
//             "user_id": "Shavae2006@yahoo.com",
//             "item_id": "hr6760-116",
//             "keyName": "hhhhhh"
//         }
//     ]
// }


//group votes and count response
//endppoint: http://localhost:5000/proverify/votes
//type: post
// {
//     "doc":"vare_vote",
//     "data": ["hr6760-116","ccxxxhhhhhh","P60012143_voteMeIn"]
// }


//find all document
//endppoint: http://localhost:5000/proverify/find/
//type: post
// {
//     "doc":"vare_vote",
//     "data": [
//         {"user_id":"Shavae2006@yahoo.com","item_id":"hr6760-116"}
//     ]
// }


//search with query document
//endppoint: http://localhost:5000/proverify/find/
//type: post
// {
//     "doc":"vare_vote",
//     "data": [
//         {"user_id":"Shavae2006@yahoo.com","item_id":"hr6760-116"}
//     ]
// }

//update document
// http://localhost:5000/proverify/update/
//post
// {
//     "key":["item_id","user_id"],
//     "doc":"vare_vote",
//     "data": [
//         {
//             "user_id": "Shavae2006@yahoo.com",
//             "item_id": "hr6760-116",
//             "keyName": "hhhhhh"
//         }
//     ]
// }

//create single document
//endppoint: http://localhost:5000/proverify/single/
//type: post
// {
//     "key":["user_id","item_id"],  //where user_id and item_id values does not exist
//     "doc":"vare_vote",
//     "data": [
//         {
//             "user_id": "Shavae2006@yahoo.com",
//             "item_id": "hr6760-116",
//             "vote": "yes",
//             "Timestamp": "0000-00-00 00:00:00",
//             "installId": "ww7FB4E2EF-4722-48F2-9324-1B51468F39B0",
//             "keyName": ""
//         }
//     ]
// }


export default {

  axiosQuery: (myRequest) => {
    const {
      request,
      resource,
      id,
      keyName,
      check,
      query
    } = myRequest

    // console.log('myRequest',myRequest)
    var orderBy = 'asc'
    var url = ''
    var data = {}
    var timestamp=`${new Date().getTime()}`
    var method = 'post'
    var route = ''

    if (request == 'read' || request == 'get') {
      url = `${'https://vare-middleware.herokuapp.com/proverify/findall?doc='}${resource}`
      data = {}
      method = 'GET'
    } else if (request == 'search') {
      url = 'https://vare-middleware.herokuapp.com/proverify/search'
      data = {
        "doc":resource,
        "data":[query ? query : {}]
      }
      method = 'POST'
    } else if (request == 'searchArray') {
      url = 'https://vare-middleware.herokuapp.com/proverify/votes'
      data = {
        "doc":"vare_vote",
        "data":query ? query : {}
      }
      method = 'POST'
    } else if (request == 'insert') { //insert
      url = 'https://vare-middleware.herokuapp.com/proverify/update'
      // query['installId'] = Constants.installationId
      data = {
        "key":check,  //where user_id and item_id values does not exist
        "doc":resource,
        "data": [query
          ? query
          : {}]
      }
      // console.log('ZZxxxx',data)
      method = 'POST'
    } else if (request == 'patch') { //edit
      url = 'https://vare-middleware.herokuapp.com/proverify/update'
      data = {
          "key":check,
          "doc":resource,
          "data": [query ? query : {}]
      }
      method = 'post'
    } else if (request == 'delete') {
      url = `${'https://vare-middleware.herokuapp.com/proverify/delete?id='}${id}&doc=${resource}`
      data = {}
      method = 'DELETE'
    }

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(data);

        var requestOptions = method != 'GET'
        ? {
          method: method,
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        }
        : {
          method: method,
          headers: myHeaders,
          redirect: 'follow'
        }

         return fetch(url, requestOptions)
          .then(response => {
            // console.log('response',response)
            return response.text()
          })
          .then(result => {
            // console.log('result',result)
            return JSON.parse(result)
          })
          .catch(error => {
            console.log('error', error)
            return []
          });
    }

  }

// export { axiosQuery };
